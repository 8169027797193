.App {
  text-align: center;
  color:#e25b4b;

}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:#FFD700 !important;
  
}

.App-link {
  color: #09d3ac;
}
/* #sidebar-app{
  z-index: -1;


} */

#button-hold{
  height: 100px;
  min-height: 20px;
}

#button-hold{
  height: 100px;
  min-height: 20px;
}

#graph-display{
  
  outline:none;
  border:black !important;
}
#graph-display > {border:blue;outline:none};


canvas{
  outline:black;
  border:black;
}
.vis-network{

  outline:black;
  border: black,
}

