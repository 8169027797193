#row_container_CFG{
    margin-top: 10px;
}
/* 
.inline{
    display: inline-block;
} */

.header-with-button{
    /* float:right; */
}

/* .definition_div{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

} */
#row-container-form{
    /* padding-bottom: 1px; */
}

#grammar_text{
    font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 26.4px; } h3 { font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 15.4px; } p { font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 20px; } blockquote { font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 17.5px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 25px; } pre { font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px  
}


#export_xmljsonCFG{
    box-shadow: none;
    /* top: -5px; */
}
#api_button_CFG{
    box-shadow:none;
    
}