#nav-header{
    font-weight: bold !important;
    /* color:#FFD700 !important; */
    border-bottom-style: solid;
    border-width: 5px;
    color: #e25b4b  !important;
    background-color: #ecfdff !important;
    /*text border for header elements
    /* text-shadow: 0 0 1px white horizontal-offset vertical-offset 'blur' colour */
    /* -moz-text-shadow: 0 0 1px rgb(156, 142, 142); */
    /* -webkit-text-shadow: 0 0 1px rgb(156, 142, 142)000; */
    /* The box-shadow is added here now */
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  
  
}
.nav-dropdown-text{
    /* color:#FFD700; */

    /* font-weight: bold;
    text-emphasis: bold; */
}

/* ID for logo text */
/* #logo_text{

} */

a.nav-link{
    color: #e25b4b !important;
}
#rflapsvg-logo{
background-size: 20% 20% !important;
}
#logo-container{
    min-width: 0% !important;
    max-width: 20% !important;
}