#row_container_reg{
    margin-top: 20px;
    /* overflow-y: auto !important;   
    overflow-x: hidden; */
}
.stop-scrolling {
    overflow: hidden;
  }
  
.row_between{
    /* margin-top:50px; */
    
}
.accord_rules{
    bottom: 0;
    /* The box-shadow is added here now */
    box-shadow: 1px 4px 12px rgba(0,0,0,0.3);
    height: 94%;
    left: 3%;
    width: 94%;
    z-index: -1;
  
}
.highlight{
    color: red;
}
#div_in{
    height: 25vh
}
#textarea_div    {
    color: black;
    border-color: rgb(92, 92, 92);
background: white ;
box-sizing: border-box;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px inset;
    position: relative;
    font-size: 0.875rem;
    font-family: Monaco, Consolas, "Andale Mono", "Lucida Console", "PT Mono", "Courier New", monospace;
    padding: 5px;
    outline: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    border-radius: 3px;
    transition: border-color 0.2s ease 0s, box-shadow, -webkit-box-shadow;
    flex: 1 0 auto;

}
/* body{background-color: #F5F5F5;} */

mark{
    background:orange;
    color:black;
}
